import * as React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';

import { initialize_title, modify_title } from '../../main/App';
import AbstractTable from '../../main/AbstractTable';
import { TextInput, SelectInput, AbstractDialog } from '../../main/AbstractForm';
import { AbstractMenu } from '../../main/AbstractMenu';
import { getTimeStr, checkJSON } from '../../main/Utility';



class Add extends AbstractDialog {
    constructor(props) {
        super('/feature/account.basic', 'post', { 'type': '' }, props.reference, 'add_diag', window.lan.account.add, window.lan.account.add_submit);
    }

    options() {
        const options = [];
        Object.entries(window.config.site.plugin).forEach(([key, item]) => {
            if (!item) return;
            options.push({ name: window.lan.plugin[key], value: key });
        });
        return [
            (<SelectInput form={this} id="type" label={window.lan.account.add_type} options={options} fullwidth />)
        ];
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.add(info.id);
        this.reference.refresh();
    }
}



class Edit extends AbstractDialog {
    constructor(props) {
        super('/feature/account.basic', 'put', {}, props.reference, 'edit_diag', window.lan.account.edit, window.lan.account.edit_submit);
    }

    options() {
        return [
            (<SelectInput form={this} id="state" label={window.lan.account.edit_state}
                options={[{ name: window.lan.account.state_inactive, value: 0 }, { name: window.lan.account.state_active, value: 1 }]} fullwidth />),
            (<TextInput form={this} id="information" label={window.lan.account.edit_information} fullwidth multiline rows={4} />)
        ];
    }

    validate(id, value) {
        if (id === 'information') return value.length > 4095 || !checkJSON(value) ? window.lan.account.err[0] : '';
        return '';
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.update(info.id);
        this.reference.refresh();
    }
}



class Del extends AbstractDialog {
    constructor(props) {
        super('/feature/account.basic', 'delete', {}, props.reference, 'del_diag', window.lan.account.del, window.lan.general.submit);
    }

    options() {
        return [window.lan.account.del_tip];
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.remove(info.list);
        this.reference.refresh();
    }
}



class Menu extends AbstractMenu {

    constructor(props) {
        const items = [];
        items.push({ name: window.lan.account.edit, icon: (<EditIcon fontSize="small" />), fun: () => { props.reference.edit_diag.value = { ...props.row }; props.reference.edit_diag.openmain(); } });
        items.push({ name: window.lan.account.del, icon: (<ClearIcon fontSize="small" />), fun: () => { props.reference.del_diag.value = { "IDList": props.row.ID }; props.reference.del_diag.openmain(); } });
        super([{ title: window.lan.general.operation, items: items }]);
    }
}



class Account extends AbstractTable {

    constructor() {
        super('/feature/account.basic',
            [
                { sortindex: 0, label: window.lan.account.id },
                { sortindex: 1, label: window.lan.account.type },
                { sortindex: 3, label: window.lan.account.counter },
                { sortindex: 4, label: window.lan.account.insert_time },
                { sortindex: 5, label: window.lan.account.update_time }
            ],
            window.lan.account.infobox, 'Account', '', '0$0', [],
            [
                window.lan.account.id,
                window.lan.account.type,
                window.lan.account.state,
                window.lan.account.counter,
                window.lan.account.insert_time,
                window.lan.account.update_time
            ], true, true);
    }

    draw() {
        initialize_title();
        modify_title(2, window.lan.account.title);
        return this.pdraw([{ info: window.lan.account.title }], (<React.Fragment><Add reference={this} /><Edit reference={this} /><Del reference={this} /></React.Fragment>), this.tdraw());
    }

    drawMenu(row, rowindex) {
        return (<Menu key={Date.now()} reference={this} row={row} rowindex={rowindex} />);
    }

    drawDetail(row, rowindex) {
        return (<Grid container justifyContent="flex-start" sx={{ padding: '5px' }}>
            <Grid item xs={12}><Typography align="left"><b>{window.lan.account.information}:</b><br />{JSON.stringify(JSON.parse(row.information), null, "\t")}</Typography></Grid>
        </Grid>);
    }

    drawCell(row, rowindex, cellindex) {
        if (cellindex === 1) return row.ID;
        if (cellindex === 2) return (<Stack direction="row" spacing={2}><Typography>{window.lan.plugin[row.type] ? window.lan.plugin[row.type] : window.lan.account.unknown}</Typography>
            {row.state === 0 ? <Chip label={window.lan.account.state_inactive} color="secondary" size="small" /> : null}</Stack>);
        if (cellindex === 3) return row.counter;
        if (cellindex === 4) return getTimeStr(row.insertTime);
        if (cellindex === 5) return getTimeStr(row.updateTime);
    }

    drawEmptyCell() {
        return window.lan.account.empty;
    }

    drawToolbarLeft() {
        return (<Button variant="contained" disableElevation onClick={() => { if (this.state.selected.length === 0) return; this.del_diag.value = { "IDList": this.state.selected.join(',') }; this.del_diag.openmain(); }}>{window.lan.account.del}</Button>);
    }

    drawToolbarRight() {
        return (<Button variant="outlined" disableElevation onClick={() => { this.add_diag.openmain(); }}>{window.lan.account.add}</Button>);
    }
}



if (!window.logfun) window.logfun = {};

window.logfun.add_account = function (info) {
    return window.lan.loginfo.add_account.replace('%A%', info.id).replace('%B%', window.lan.plugin[info.type] ? window.lan.plugin[info.type] : window.lan.account.unknown);
}

window.logfun.remove_accounts = function (info) {
    return window.lan.loginfo.remove_accounts.replace('%A%', info.ids.join(', '));
}

window.logfun.edit_account = function (info) {
    return window.lan.loginfo.edit_account.replace('%A%', info.id).replace('%B%', info.state === 0 ? window.lan.account.state_inactive : window.lan.account.state_active);
}



export default Account;