import * as React from 'react';

import { TooltipWrapper, TextInput, SelectInput, AbstractDialog } from '../../main/AbstractForm';



class Feature extends AbstractDialog {
    constructor(props) {
        super('/feature/feature.basic', 'put', { 'featureID': '' }, props.reference, 'feature', window.lan.feature.title, window.lan.feature.submit);
    }

    options() {
        const options = [];
        Object.entries(window.config.site.table).forEach(([key, item]) => {
            if (item[3]) options.push({ name: window.lan.table[item[1]], value: key });
        });

        return [
            (<SelectInput form={this} id="name" label={window.lan.feature.edit_feature} options={options} fullwidth />),
            (<TooltipWrapper input={<TextInput form={this} id="featureID" label={window.lan.feature.edit_id} fullwidth />} tooltip={window.lan.feature.edit_id_tip} />)
        ];
    }

    validate(id, value) {
        if (id === 'featureID') return isNaN(parseInt(value)) || parseInt(value) < 1 || parseInt(value) > 2147483647 ? window.lan.feature.err[0] : '';
        if (id === 'name') return value === '' ? window.lan.feature.err[1] : '';
        return '';
    }

    result(result, info) {
        if (result === 0)
            this.openresult(window.lan.feature.okay);
    }
}



export default Feature;