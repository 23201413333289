import * as React from 'react';

import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';

import { initialize_title, modify_title, get_url } from '../../main/App';
import AbstractTable from '../../main/AbstractTable';
import { TextInput, FileInput, AbstractDialog } from '../../main/AbstractForm';
import { AbstractMenu } from '../../main/AbstractMenu';
import { getTimeStr } from '../../main/Utility';



class Add extends AbstractDialog {
    constructor(props) {
        super('/feature/document.basic/add', 'file', { 'name': '', 'file': { 'size': 0 } }, props.reference, 'add_diag', window.lan.document.add, window.lan.document.add_submit);
    }

    options() {
        return [
            (<TextInput form={this} id="name" label={window.lan.document.add_name} fullwidth />),
            (<FileInput form={this} id="file" label={window.lan.document.add_file} />)
        ];
    }

    validate(id, value) {
        if (id === 'name') return value.length < 1 || value.length > 31 ? window.lan.document.err[0] : '';
        if (id === 'file') return value.size < 1 || value.size > 10485760 ||
            'zip|jpg|bmp|gif|png|mp3|mp3|mid|txt|doc|docx|ppt|pptx|pdf|ico|json|ttf'.split('|').indexOf(value.name ? value.name.substr(value.name.lastIndexOf('.') + 1).toLowerCase() : '') < 0
            ? window.lan.document.err[1] : '';
        return '';
    }

    presubmit() {
        if (!super.presubmit()) return false;
        this.path = '/feature/document.basic/add?name=' + encodeURIComponent(this.value.name);
        return true;
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.add(info.id);
        this.reference.refresh();
    }
}



class Edit extends AbstractDialog {
    constructor(props) {
        super('/feature/document.basic/edit', 'file', { 'file': { 'size': 0 } }, props.reference, 'edit_diag', window.lan.document.edit, window.lan.document.edit_submit);
    }

    options() {
        return [
            (<TextInput form={this} id="name" label={window.lan.document.edit_name} fullwidth />),
            (<FileInput form={this} id="file" label={window.lan.document.edit_file} />)
        ];
    }

    validate(id, value) {
        if (id === 'name') return value.length < 1 || value.length > 31 ? window.lan.document.err[0] : '';
        if (id === 'file') return value.name && (value.size > 10485760 ||
            'zip|jpg|bmp|gif|png|mp3|mp3|mid|txt|doc|docx|ppt|pptx|pdf|ico|json|ttf'.split('|').indexOf(value.name ? value.name.substr(value.name.lastIndexOf('.') + 1).toLowerCase() : '') < 0)
            ? window.lan.document.err[1] : '';
        return '';
    }

    presubmit() {
        if (!super.presubmit()) return false;
        this.path = '/feature/document.basic/edit?ID=' + this.value.ID + '&name=' + encodeURIComponent(this.value.name);
        return true;
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.update(info.id);
        this.reference.refresh();
    }
}



class Del extends AbstractDialog {
    constructor(props) {
        super('/feature/document.basic', 'delete', {}, props.reference, 'del_diag', window.lan.document.del, window.lan.general.submit);
    }

    options() {
        return [window.lan.document.del_tip];
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.remove(info.list);
        this.reference.refresh();
    }
}



class Menu extends AbstractMenu {

    constructor(props) {
        const items = [];
        items.push({ name: window.lan.document.edit, icon: (<EditIcon fontSize="small" />), fun: () => { props.reference.edit_diag.value = { ...props.row }; props.reference.edit_diag.value['file'] = {}; props.reference.edit_diag.openmain(); } });
        items.push({ name: window.lan.document.del, icon: (<ClearIcon fontSize="small" />), fun: () => { props.reference.del_diag.value = { "IDList": props.row.ID }; props.reference.del_diag.openmain(); } });
        super([{ title: window.lan.general.operation, items: items }]);
    }
}



class Document extends AbstractTable {

    constructor() {
        super('/feature/document.basic',
            [
                { sortindex: 0, label: window.lan.document.id },
                { sortindex: 1, label: window.lan.document.name },
                { sortindex: 2, label: window.lan.document.file },
                { sortindex: -1, label: window.lan.document.size },
                { sortindex: 3, label: window.lan.document.update_time }
            ],
            window.lan.document.infobox, 'Document', '', '0$0', [],
            [
                window.lan.document.id,
                window.lan.document.name,
                window.lan.document.file,
                window.lan.document.update_time
            ], true, false);
    }

    draw() {
        initialize_title();
        modify_title(2, window.lan.document.title);
        return this.pdraw([{ info: window.lan.document.title }], (<React.Fragment><Add reference={this} /><Edit reference={this} /><Del reference={this} /></React.Fragment>), this.tdraw());
    }

    drawMenu(row, rowindex) {
        return (<Menu key={Date.now()} reference={this} row={row} rowindex={rowindex} />);
    }

    drawCell(row, rowindex, cellindex) {
        if (cellindex === 1) return row.ID;
        if (cellindex === 2) return row.name;
        if (cellindex === 3) return <Link target="_blank" href="" onClick={(e) => { e.target.href = get_url('/feature/document.basic/download?ID=' + row.ID); return true; }}>{row.file}</Link>;
        if (cellindex === 4) return window.lan.document.fsize.replace('%A%', (row.size / 1024).toFixed(3))
        if (cellindex === 5) return getTimeStr(row.updateTime);
    }

    drawEmptyCell() {
        return window.lan.document.empty;
    }

    drawToolbarLeft() {
        return (<Button variant="contained" disableElevation onClick={() => { if (this.state.selected.length === 0) return; this.del_diag.value = { "IDList": this.state.selected.join(',') }; this.del_diag.openmain(); }}>{window.lan.document.del}</Button>);
    }

    drawToolbarRight() {
        return (<Button variant="outlined" disableElevation onClick={() => { this.add_diag.openmain(); }}>{window.lan.document.add}</Button>);
    }
}



if (!window.logfun) window.logfun = {};

window.logfun.add_document = function (info) {
    return window.lan.loginfo.add_document.replace('%A%', info.id).replace('%B%', info.file).replace('%C%', info.name);
}

window.logfun.remove_documents = function (info) {
    return window.lan.loginfo.remove_documents.replace('%A%', info.ids.join(', '));
}

window.logfun.edit_document = function (info) {
    return window.lan.loginfo.edit_document.replace('%A%', info.id).replace('%B%', info.name);
}



export default Document;