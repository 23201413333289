import MainBarBasic from './feature/_mainbar.basic/main';
import StatusBarBasic from './feature/_statusbar.basic/main';
import MenuSystem from './feature/_menu.system/main';
import MenuSaturn from './feature/_menu.saturn/main';

import LoginBasic from './feature/login.basic/main';
import LogoutBasic from './feature/logout.basic/main';
import PasswordBasic from './feature/password.basic/main';
import HistoryBasic from './feature/history.basic/main';
import InformationSystem from './feature/information.system/main';
import Saturn from './feature/saturn.saturn/main';
import SiteBasic from './feature/site.basic/main';
import LogBasic from './feature/log.basic/main';
import GroupBasic from './feature/group.basic/main';
import AdministratorBasic from './feature/administrator.basic/main';
import FeatureBasic from './feature/feature.basic/main';
import StaticBasic from './feature/static.basic/main';
import PageBasic from './feature/page.basic/main';
import AccountBasic from './feature/account.basic/main';
import DocumentBasic from './feature/document.basic/main';
import ServiceBasic from './feature/service.basic/main';
import LevelBasic from './feature/level.basic/main';
import DraftBasic from './feature/draft.basic/main';



export function loadFeatures() {
    return {
        _menu: { system: MenuSystem, saturn: MenuSaturn },
        _mainbar: { basic: MainBarBasic },
        _statusbar: { basic: StatusBarBasic },
        login: { basic: LoginBasic },
        logout: { basic: LogoutBasic },
        password: { basic: PasswordBasic },
        history: { basic: HistoryBasic },
        information: { system: InformationSystem },
        saturn: { system: InformationSystem, saturn: Saturn },
        site: { basic: SiteBasic },
        log: { basic: LogBasic },
        group: { basic: GroupBasic },
        administrator: { basic: AdministratorBasic },
        feature: { basic: FeatureBasic },
        static: { basic: StaticBasic },
        page: { basic: PageBasic },
        account: { basic: AccountBasic },
        document: { basic: DocumentBasic },
        service: { basic: ServiceBasic },
        level: { basic: LevelBasic },
        draft: { basic: DraftBasic }
    };
}

export function loadPlugins() {
    return {
    };
}