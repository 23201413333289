import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Chart, registerables } from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';

import { initialize_title, modify_title } from '../../main/App';
import { AbstractPage } from '../../main/AbstractPage';

import { getUTCDateStr } from '../../main/Utility';



Chart.register(...registerables);



class Information extends AbstractPage {
	constructor() {
		super('/feature/saturn.saturn');
		this.state = { info: null };
	}

	result(result, info) {
		this.setState({ info: info });
	}

	color(value) {
		if (value < 0.8) return '#0070E0';
		if (value < 1) return '#C07000';
		return '#C03030';
	}

	chartUsage() {
		const labelArr = [];
		const dataArr = [];
		window.config.site.table.forEach((item) => {
			if (item[2] === 0 || !this.state.info.hasOwnProperty('feature.' + item[1])) return;
			const value = item[2] * this.state.info['feature.' + item[1]];
			let index = dataArr.length;
			while (index > 0 && value > dataArr[index - 1]) index--;
			labelArr.splice(index, 0, window.lan.table[item[1]]);
			dataArr.splice(index, 0, value);
		});

		let other = 0;
		let length = dataArr.length;
		dataArr.forEach((value, index) => {
			if (index > 6 || value / this.state.info.size < 0.03) {
				other += value;
				if (length === dataArr.length) length = index;
			}
		});
		labelArr.splice(length, labelArr.length - length);
		dataArr.splice(length, dataArr.length - length);

		const colorArr = ['#396AB1', '#DA7C30', '#3E9651', '#CC2529', '#535154', '#6B4C9A', '#922428', '#948B3D'];
		colorArr.splice(dataArr.length, colorArr.length);
		if (other > 0) {
			colorArr.push('#404040');
			labelArr.push(window.lan.saturn.other_detail);
			dataArr.push(other);
		}
		return <React.Fragment><Typography><b>{window.lan.saturn.usage_detail}</b><br /><br /></Typography><div style={{ width: 150 }}><Doughnut options={{ plugins: { legend: false } }} data={{
			labels:
				labelArr,
			datasets: [{
				backgroundColor: colorArr,
				data: dataArr,
			}]
		}} /></div></React.Fragment>;
	}

	chartSize() {
		return <React.Fragment><Typography><b>{window.lan.saturn.usage_size}</b><br /><br /></Typography><div style={{ width: 150 }}><Doughnut options={{ plugins: { legend: false } }} data={{
			labels:
				[window.lan.saturn.used_size, window.lan.saturn.free_size],
			datasets: [{
				backgroundColor: [this.color(this.state.info.size / (this.state.info.license > 0 ? this.state.info.license : 1)), '#A0A0A0'],
				data: [this.state.info.size, Math.max(0, this.state.info.license - this.state.info.size)],
			}]
		}} /></div></React.Fragment>;
	}

	chartStatt() {
		const dt = new Date();
		dt.setTime(this.state.info.benchmark['benchmark.base'] * 1000);
		const lArr = [];
		const tArr = [];
		for (let i = 0; i < 30; i++) {
			const year = dt.getUTCFullYear();
			const month = dt.getUTCMonth();
			const date = dt.getUTCDate();
			lArr.push(window.lan.saturn.day.replace('%A%', year).replace('%B%', (month < 9 ? '0' : '') + (month + 1)).replace('%C%', (date < 10 ? '0' : '') + date));
			tArr.push(this.state.info.benchmark['benchmark.sale.t' + i]);
			dt.setUTCDate(date - 1);
		}
		return <React.Fragment><Typography><b>{window.lan.saturn.sale_t}</b><br /><br /></Typography><div style={{ width: '48vw' }}><Bar options={{ plugins: { legend: false }, aspectRatio: 3.5 }} data={{
			labels:
				lArr.reverse(),
			datasets: [{
				backgroundColor: '#3E9651',
				data: tArr,
			}]
		}} /></div></React.Fragment>;
	}

	chartStats() {
		const dt = new Date();
		dt.setTime(this.state.info.benchmark['benchmark.base'] * 1000);
		const lArr = [];
		const sArr = [];
		for (let i = 0; i < 30; i++) {
			const year = dt.getUTCFullYear();
			const month = dt.getUTCMonth();
			const date = dt.getUTCDate();
			lArr.push(window.lan.saturn.day.replace('%A%', year).replace('%B%', (month < 9 ? '0' : '') + (month + 1)).replace('%C%', (date < 10 ? '0' : '') + date));
			sArr.push(this.state.info.benchmark['benchmark.sale.s' + i] / 100);
			dt.setUTCDate(date - 1);
		}
		return <React.Fragment><Typography><b>{window.lan.saturn.sale_s}</b><br /><br /></Typography><div style={{ width: '48vw' }}><Bar options={{ plugins: { legend: false }, aspectRatio: 3.5 }} data={{
			labels:
				lArr.reverse(),
			datasets: [{
				backgroundColor: '#3E9651',
				data: sArr,
			}]
		}} /></div></React.Fragment>;
	}

	tdraw() {
		const size = this.state.info.size / (this.state.info.license > 0 ? this.state.info.license : 1);
		return (<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
			<Grid item xs={6}>
				<Typography><b>{window.config.site.name}</b><br /><br /></Typography>
				<TableContainer component={Grid}>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell>{window.lan.saturn.version}</TableCell>
								<TableCell>{window.lan.saturn.backClient}</TableCell>
								<TableCell>{window.lan.saturn.backServer}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell>{this.state.info.version}</TableCell>
								<TableCell>{this.state.info.backClient}</TableCell>
								<TableCell>{this.state.info.backServer}</TableCell>
							</TableRow>
						</TableBody>
						<TableHead>
							<TableRow>
								<TableCell>{window.lan.saturn.email}</TableCell>
								<TableCell>{window.lan.saturn.frontClient}</TableCell>
								<TableCell>{window.lan.saturn.frontServer}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell>{window.config.site.email === '' ? window.lan.general.none : window.config.site.email}</TableCell>
								<TableCell><Link href={'https://' + this.state.info.frontClient} underline="none" target="_blank">{this.state.info.frontClient}</Link></TableCell>
								<TableCell>{this.state.info.frontServer}</TableCell>
							</TableRow>
						</TableBody>
						<TableHead>
							<TableRow>
								<TableCell>{window.lan.saturn.size_license}</TableCell>
								<TableCell>{window.lan.saturn.valid_time}</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell>{this.state.info.size} / {this.state.info.license}</TableCell>
								<TableCell>{getUTCDateStr(this.state.info.validTime)}</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			<Grid item xs={2}>
				<Typography><b>{window.lan.saturn.plugin_list}</b><br /><br /></Typography>
				{Object.entries(window.config.site.plugin).map(([key, item]) => item ? <Typography key={key}>{window.lan.plugin[key]}</Typography> : null)}
			</Grid>
			<Grid item xs={4} style={{ marginTop: "-100px" }}>
				<Grid container direction="row" justifyContent="center" alignItems="center" spacing={3} style={{ height: "350px" }}>
					<Grid item>
						{this.chartSize()}<Typography sx={{ fontSize: "23px", marginTop: "-95px" }} color={this.color(size)}>{Math.floor(size * 100)}%</Typography>
					</Grid>
					<Grid item>
						{this.chartUsage()}<Typography sx={{ fontSize: "23px", marginTop: "-95px" }} color={this.color(size)}>&nbsp;</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}><br /><br /></Grid>
			<Grid item xs={6}>
				{this.chartStatt()}
			</Grid>
			<Grid item xs={6}>
				{this.chartStats()}
			</Grid>
		</Grid>);
	}

	draw() {
		initialize_title();
		modify_title(2, window.lan.saturn.title);
		return this.pdraw([{ info: window.lan.saturn.title }], null, null, this.tdraw());
	}
}



export default Information;