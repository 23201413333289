import * as React from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import InventoryIcon from '@mui/icons-material/Inventory';

import { initialize_title, load, modify_title } from '../../main/App';
import AbstractTable from '../../main/AbstractTable';
import { TextInput, AbstractDialog } from '../../main/AbstractForm';
import { AbstractMenu } from '../../main/AbstractMenu';



class Add extends AbstractDialog {
    constructor(props) {
        super('/feature/level.basic', 'post', { 'name': '', 'level': '' }, props.reference, 'add_diag', window.lan.level.add, window.lan.level.add_submit);
    }

    options() {
        return [
            (<Stack direction="row" spacing={5}>
                <TextInput form={this} id="name" label={window.lan.level.add_name} fullwidth />
                <TextInput form={this} id="level" label={window.lan.level.add_level} fullwidth />
            </Stack>)
        ];
    }

    validate(id, value) {
        if (id === 'name') return value.length < 1 || value.length > 31 ? window.lan.level.err[1] : '';
        if (id === 'level') return isNaN(parseInt(value)) || parseInt(value) < 1 || parseInt(value) > 4095 ? window.lan.level.err[0] : '';
        return '';
    }

    result(result, info) {
        if (result === 9) {
            this.validity['level'] = window.lan.level.err[0];
            this.openmain();
            return;
        }
        if (result === 0)
            this.reference.dm.add(info.id);
        this.reference.refresh();
    }
}



class Edit extends AbstractDialog {
    constructor(props) {
        super('/feature/level.basic', 'put', {}, props.reference, 'edit_diag', window.lan.level.edit, window.lan.level.edit_submit);
    }

    options() {
        return [
            (<TextInput form={this} id="name" label={window.lan.level.edit_name} fullwidth />),
        ];
    }

    validate(id, value) {
        if (id === 'name') return value.length < 1 || value.length > 31 ? window.lan.level.err[1] : '';
        return '';
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.update(info.id);
        this.reference.refresh();
    }
}



class Del extends AbstractDialog {
    constructor(props) {
        super('/feature/level.basic', 'delete', {}, props.reference, 'del_diag', window.lan.level.del, window.lan.general.submit);
    }

    options() {
        return [window.lan.level.del_tip];
    }

    result(result, info) {
        if (result === 2) {
            this.openresult(window.lan.level.err[2]);
            return;
        }
        if (result === 0)
            this.reference.dm.remove(info.list);
        this.reference.refresh();
    }
}



class Menu extends AbstractMenu {

    constructor(props) {
        const items = [];
        if (window.config.admin.right[3] & 2) items.push({ name: window.lan.level.article_tip, icon: (<InventoryIcon fontSize="small" />), fun: () => { load('/article/3$' + props.row.level); } });
        items.push({});
        items.push({ name: window.lan.level.edit, icon: (<EditIcon fontSize="small" />), fun: () => { props.reference.edit_diag.value = { ...props.row }; props.reference.edit_diag.openmain(); } });
        items.push({ name: window.lan.level.del, icon: (<ClearIcon fontSize="small" />), fun: () => { props.reference.del_diag.value = { "IDList": props.row.ID }; props.reference.del_diag.openmain(); } });
        super([{ title: window.lan.general.operation, items: items }]);
    }
}



class Level extends AbstractTable {

    constructor() {
        super('/feature/level.basic',
            [
                { sortindex: 0, label: window.lan.level.id },
                { sortindex: 1, label: window.lan.level.level },
                { sortindex: 2, label: window.lan.level.name, style: { width: '60%' } }
            ],
            window.lan.level.infobox, 'Level', '', '0$0', [],
            [
                window.lan.level.id,
                window.lan.level.index,
                window.lan.level.name
            ], true);
    }

    draw() {
        initialize_title();
        modify_title(2, window.lan.level.title);
        return this.pdraw([{ info: window.lan.level.title }], (<React.Fragment><Add reference={this} /><Edit reference={this} /><Del reference={this} /></React.Fragment>), this.tdraw());
    }

    drawMenu(row, rowindex) {
        return (<Menu key={Date.now()} reference={this} row={row} rowindex={rowindex} />);
    }

    drawCell(row, rowindex, cellindex) {
        if (cellindex === 1) return row.ID;
        if (cellindex === 2) return row.level;
        if (cellindex === 3) return (<Typography>{row.name}</Typography>);
    }

    drawEmptyCell() {
        return window.lan.level.empty;
    }

    drawToolbarLeft() {
        return (<Button variant="contained" disableElevation onClick={() => { if (this.state.selected.length === 0) return; this.del_diag.value = { "IDList": this.state.selected.join(',') }; this.del_diag.openmain(); }}>{window.lan.level.del}</Button>);
    }

    drawToolbarRight() {
        return (<Button variant="outlined" disableElevation onClick={() => { this.add_diag.openmain(); }}>{window.lan.level.add}</Button>);
    }
}



if (!window.logfun) window.logfun = {};

window.logfun.add_level = function (info) {
    return window.lan.loginfo.add_level.replace('%A%', info.id).replace('%B%', info.level).replace('%B%', info.name);
}

window.logfun.remove_levels = function (info) {
    return window.lan.loginfo.remove_levels.replace('%A%', info.ids.join(', '));
}

window.logfun.edit_level = function (info) {
    return window.lan.loginfo.edit_level.replace('%A%', info.id).replace('%B%', info.name);
}



export default Level;